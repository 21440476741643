.left-corner{
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: 0px !important;
  }
  .is_fullscreen{
    position: fixed !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 4.25rem;
    max-height: revert !important;
    background-color: #363636;
    z-index: 10;
  }
  .is_fullscreen video{
    height: 100% !important;
    max-height: revert;
  }
  .hide{
    display: none !important;
  }
  .is-peer-name{
    margin: 10vh 0;
  }
  video{
    background: #242424;
    width: 100%;
    max-height: 77vh;
  }
  .is-peer-box{
    border: white;
    border-style: dotted;
    /* min-height: 27vh; */
    max-height: 80vh;
  }
  @media screen and (max-height: 630px) {
    .hero-body{
      padding-top: 0 !important;
    }
    video{
      max-height: 60vh;
    }
    .is-peer-box{
      max-height: 66vh;
    }
  }
  div[class*="page-"]{
    display: none;
  }
  .show-page{
    display: block !important;
  }
  .pagination-link{
    color: white !important;
  }
  a.navbar-item:hover{
    background-color: inherit !important;
  }
  
  .navbar-control-item {
    cursor: pointer;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    padding: 0 4px;
  }
  .navbar-controls {
    display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
  }
  .navbar-controls.start{
    justify-content: flex-start;
  }
  .navbar-controls.center{
    justify-content: center;
    flex: 1;
  }
  .navbar-controls.end{
    justify-content: flex-end;
  }
  .debug-data{
      position: absolute;
      top: 0px;
      left: 30px;
      font-family: "Cascadia Code";
      color: whitesmoke;
      white-space: pre-line;
      text-align: left;
      font-size: 10pt;
  }
  .relative-box{
    position: relative;
  }
  video.mini{
    position: absolute;
    float: right;
    width: 20vw;
    bottom: 0;
    right: 0;
    z-index: 99;
  }